'use client'

import { ComponentHelperNavLinkGroupFragment } from '@generated/schema'
import ClosedGroup from '../ClosedGroup/ClosedGroup.client'
import NavLink from '../NavLink/NavLink.client'

interface IOpenedGroupProps {
  item: ComponentHelperNavLinkGroupFragment
  setOpenedGroup: (item: ComponentHelperNavLinkGroupFragment | null) => void
  navbarId: string
  index: number
}

export default function OpenedGroup({
  item,
  setOpenedGroup,
  navbarId,
  index,
}: IOpenedGroupProps) {
  return (
    <li>
      <div className='f-sm-5 mb-sm-4'>{item.title}</div>
      <ul className='list-style-none m-sm-0 mb-sm-4 p-sm-0 pb-sm-4 border-sm-bottom border-static-grey3'>
        {item.itemsCollection?.items.map((openedGroupItem) => {
          if (!openedGroupItem) return null

          if (openedGroupItem.__typename === 'ComponentHelperNavLinkGroup') {
            return (
              <ClosedGroup
                key={openedGroupItem.sys.id}
                item={openedGroupItem as ComponentHelperNavLinkGroupFragment}
                setOpenedGroup={setOpenedGroup}
              />
            )
          }

          return (
            <NavLink
              key={openedGroupItem.sys.id}
              item={openedGroupItem}
              navbarId={navbarId}
              index={index}
            />
          )
        })}
      </ul>
    </li>
  )
}
