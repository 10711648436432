'use client'

import { ComponentHelperNavLinkGroupFragment } from '@generated/schema'
import SvgCaretRight from 'app/components/Svg/SvgCaretRight'
import styles from './ClosedGroup.module.scss'

interface IClosedGroupProps {
  item: ComponentHelperNavLinkGroupFragment
  setOpenedGroup: (item: ComponentHelperNavLinkGroupFragment | null) => void
  isFirstLayer?: boolean
}

export default function ClosedGroup({
  item,
  setOpenedGroup,
  isFirstLayer,
}: IClosedGroupProps) {
  return (
    <li
      className={`${styles.listItem} ${
        isFirstLayer ? 'pb-sm-4 border-sm-bottom border-static-grey3' : ''
      }`}
    >
      <button
        type='button'
        onClick={() => setOpenedGroup(item)}
        className={`${styles.button} ${
          isFirstLayer ? 'f-sm-5' : 'f-sm-3'
        } Btn-link w-sm-full d-sm-flex flex-sm-justify-between flex-sm-align-center`}
      >
        <span>{item.title}</span>
        <SvgCaretRight className='w-sm-4 fill' />
      </button>
    </li>
  )
}
