'use client'

import {
  ComponentHelperExternalLinkFragment,
  ComponentHelperNavLinkFragment,
  ComponentHelperNavLinkGroupFragment,
} from '@generated/schema'
import SvgCaretLeft from 'app/components/Svg/SvgCaretLeft'
import useTranslation from 'app/i18n/client'
import { useState } from 'react'
import ClosedGroup from './ClosedGroup/ClosedGroup.client'
import styles from './NavbarSidePanelLinkTree.module.scss'
import NavLink from './NavLink/NavLink.client'
import OpenedGroup from './OpenedGroup/OpenedGroup.client'

interface INavbarSidePanelLinkTreeProps {
  items: (
    | ComponentHelperNavLinkFragment
    | ComponentHelperNavLinkGroupFragment
    | ComponentHelperExternalLinkFragment
  )[]
  navbarId: string
  children: React.ReactNode
}

function NavbarSidePanelLinkTree({
  items,
  navbarId,
  children,
}: INavbarSidePanelLinkTreeProps) {
  const [expandedGroup, setExpandedGroup] =
    useState<ComponentHelperNavLinkGroupFragment | null>(null)
  const { t } = useTranslation()

  return (
    <div className='o-sm-hidden'>
      <div
        className={`d-sm-flex ${styles.container} ${
          expandedGroup ? styles.groupOpened : ''
        }`}
      >
        <div className={styles.panel}>
          {!expandedGroup && (
            <>
              <ul className='list-style-none m-sm-0 p-sm-0'>
                {items.map((item, index) => {
                  if (item?.__typename === 'ComponentHelperNavLinkGroup') {
                    //  The first group is always opened
                    if (index === 0) {
                      return (
                        <OpenedGroup
                          key={item.sys.id}
                          item={item}
                          setOpenedGroup={setExpandedGroup}
                          navbarId={navbarId}
                          index={index}
                        />
                      )
                    }
                    // other groups are closed
                    return (
                      <ClosedGroup
                        key={item.sys.id}
                        item={item}
                        setOpenedGroup={setExpandedGroup}
                        isFirstLayer
                      />
                    )
                  }

                  return (
                    <NavLink
                      key={item.sys.id}
                      item={item}
                      navbarId={navbarId}
                      index={index}
                      isFirstLayer
                    />
                  )
                })}
              </ul>
              {children}
            </>
          )}
        </div>

        {/* expanded group */}
        <div className={styles.panel}>
          {expandedGroup && (
            <>
              <button
                type='button'
                onClick={() => setExpandedGroup(null)}
                className={`${styles.backButton} Btn-link d-sm-flex flex-sm-align-center f-sm-2 mb-sm-5 ml-sm-n1`}
              >
                <SvgCaretLeft className='w-sm-4 mr-sm-1' />
                {t('back')}
              </button>
              <div className='f-sm-3 text-bold mb-sm-4'>
                {expandedGroup?.title}
              </div>
              <ul className='list-style-none m-sm-0 p-sm-0 pl-sm-4'>
                {expandedGroup?.itemsCollection?.items.map((item, index) => {
                  if (
                    !item ||
                    item.__typename === 'ComponentHelperNavLinkGroup'
                  )
                    return null
                  return (
                    <NavLink
                      key={item.sys.id}
                      item={item}
                      navbarId={navbarId}
                      index={index}
                    />
                  )
                })}
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default NavbarSidePanelLinkTree
