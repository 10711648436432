export default function getContentUrl(content: any): string {
  if (!content) return ''

  const { __typename: type } = content
  let url = ''

  if (type === 'Article') {
    url = `/${content.locale || 'en'}/articles/${content.slug}`
  } else if (type === 'Podcast') {
    url = `/${content.locale || 'en'}/podcasts/${content.slug}`
  } else if (type === 'PodcastEpisode') {
    const podcast = content.linkedFrom?.podcastCollection?.items[0]
    url = `/${podcast?.locale || 'en'}/podcasts/${podcast?.slug}/${
      content.slug
    }`
  } else if (type === 'Page') {
    url = `/${content.isLocalizable ? '' : 'en'}/${content.slug}`
  } else if (type === 'PageGeneric') {
    url = `/${content.showSpanishPage ? '' : 'en'}/${content.slug}`
  } else if (type === 'PageTopics') {
    url = `/topics/${content.externalTag?.slug}`
  } else if (type === 'PageAudiences') {
    url = `/${content.showSpanishPage ? '' : 'en'}/audiences/${content.slug}`
  } else if (type === 'PageHome') {
    url = `/${content.showSpanishPage ? '' : 'en'}/${content.slug}`
  } else if (type === 'DownloadableAsset') {
    url = content.asset?.url || ''
  } else if (type === 'Person') {
    url = content.slug ? `/people/${content.slug}` : `/people/${content.sys.id}`
  } else if (type === 'PressRelease') {
    url = `/press-releases/${content.slug}`
  } else if (type === 'ResearchAndSurveys') {
    url = `/research-and-surveys/${content.reseachSlug}`
  } else if (type === 'NewsAppearance') {
    url = `${content.newsAppearanceLinkToPublication}`
  } else {
    url = ''
  }

  return url.replace(/\/\//g, '/')
}
