import { ISvgProps } from './Svg.interface'

export default function SvgProfileFilled({ className = '' }: ISvgProps) {
  return (
    <svg
      className={className}
      data-testid='svg-profile-filled'
      fill='currentColor'
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      focusable='false'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25 0c-8.3 0-15 6.7-15 15s6.7 15 15 15 15-6.7 15-15S33.3 0 25 0zm20 44c0-5.5-4.5-10-10-10H15C9.5 34 5 38.5 5 44v6h40v-6z'
      />
    </svg>
  )
}
