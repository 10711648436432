/* eslint jsx-a11y/no-static-element-interactions: 0 */
/* eslint jsx-a11y/click-events-have-key-events: 0 */

'use client'

import UserContext from '@context/UserContext/UserContext'
import { GetNavbarQuery } from '@generated/schema'
import useGlobalContext from 'app/components/GlobalContext/useGlobalContext'
import SvgProfile from 'app/components/Svg/SvgProfile'
import SvgSearch from 'app/components/Svg/SvgSearch'
import SvgClose from 'app/components/Svg/SvgClose'
import SvgMenu from 'app/components/Svg/SvgMenu'
import TrackClick from 'app/components/TrackClick/TrackClick.client'
import useTranslation from 'app/i18n/client'
import { usePathname, useRouter } from 'next/navigation'
import { useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import SvgProfileFilled from 'app/components/Svg/SvgProfileFilled'
import FocusTrap from 'focus-trap-react'
import styles from './NavbarSidePanel.module.scss'
import NavbarSidePanelLinkTree from './NavbarSidePanelLinkTree/NavbarSidePanelLinkTree.client'

interface INavbarPanelClientProps {
  navbarData: GetNavbarQuery
}

function NavbarSidePanel({ navbarData }: INavbarPanelClientProps) {
  const navbar = navbarData.componentNavbarCollection?.items[0]
  const router = useRouter()
  const { t } = useTranslation()
  const { locale, slugEn, slugEs } = useGlobalContext()
  const { updateAccountModal, user, logoutUser } = useContext(UserContext)
  const [isOpened, setIsOpened] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const [isSearchOpened, setIsSearchOpened] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const pathname = usePathname()

  useEffect(() => {
    setIsOpened(false)
  }, [pathname])

  useEffect(() => {
    // this is needed to avoid hydration error
    setIsMounted(true)

    function handleEscapeKey(event: KeyboardEvent) {
      if (event.code === 'Escape') {
        setIsOpened(false)
      }
    }

    document.addEventListener('keydown', handleEscapeKey)
    return () => document.removeEventListener('keydown', handleEscapeKey)
  }, [])

  const handleSearch = (e) => {
    e.preventDefault()
    setSearchTerm('')
    router.push(`/search-results?query=${searchTerm}`)
  }

  if (!navbar) return null

  return (
    <>
      {/* Menu button */}
      <TrackClick
        as='button'
        type='button'
        aria-label={t('open-menu')}
        title={t('open-menu')}
        className='Btn-link flex-sm-align-center flex-sm-justify-center target'
        data-testid='header-icon-open-menu'
        onClick={() => setIsOpened((prev) => !prev)}
        isEngagementEvent={false}
        trackProps={{
          clickType: 'button',
          buttonName: 'sidebar_menu',
          unitName: 'main_menu',
          unitLocation: 'header',
          componentId: navbar.sys.id,
        }}
      >
        <SvgMenu className='w-sm-4' />
      </TrackClick>

      {isMounted &&
        createPortal(
          <>
            <div
              className={`${styles.backdrop} ${
                isOpened ? styles.show : ''
              } p-sm-fixed left-sm-0 top-sm-0 bg-static-black`}
            />

            <FocusTrap
              active={isOpened}
              open
              focusTrapOptions={{
                onDeactivate: () => setIsOpened(false),
                clickOutsideDeactivates: true,
                escapeDeactivates: true,
              }}
            >
              <div
                className={`${styles.sideContainer} ${
                  isOpened ? styles.show : ''
                } o-sm-auto p-sm-fixed p-sm-5 right-sm-0 top-sm-0 bg-background-low-white`}
                data-testid='header-sidebar'
              >
                {/* Top icons */}
                <div className={`${isSearchOpened ? 'mb-sm-5' : 'mb-sm-6'}`}>
                  <div
                    className={`${styles.iconsContainer} d-sm-flex flex-sm-align-center m-sm-n3`}
                  >
                    {/* Language toggle button */}
                    {slugEn && slugEs && (
                      <TrackClick
                        className='text-foreground-midblueShade hover-underline text-regular m-sm-3'
                        data-testid='header-language-toggle'
                        href={locale === 'en' ? slugEs : slugEn}
                        locale={locale === 'en' ? 'es-mx' : 'en'}
                        isEngagementEvent={false}
                        trackProps={{
                          clickType: 'text',
                          linkText: t('language-toggle'),
                          unitName: 'sidebar_menu',
                          unitLocation: 'modal',
                          componentId: navbar.sys.id,
                        }}
                      >
                        {t('language-toggle')}
                      </TrackClick>
                    )}

                    {/* Search button */}
                    <TrackClick
                      as='button'
                      type='button'
                      aria-label={
                        isSearchOpened ? t('close-search') : t('open-search')
                      }
                      title={
                        isSearchOpened ? t('close-search') : t('open-search')
                      }
                      className='Btn-link flex-sm-align-center flex-sm-justify-center target'
                      data-testid='header-icon-toggle-search'
                      onClick={() => setIsSearchOpened((prev) => !prev)}
                      isEngagementEvent={false}
                      style={{ marginLeft: 'auto' }}
                      trackProps={{
                        clickType: 'button',
                        buttonName: 'search',
                        unitName: 'sidebar_menu',
                        unitLocation: 'modal',
                        componentId: navbar.sys.id,
                      }}
                    >
                      <SvgSearch className='w-sm-4 m-sm-3' />
                    </TrackClick>

                    {/* Authentication button */}
                    {user?.is_logged_in ? (
                      <TrackClick
                        as='button'
                        type='button'
                        aria-label={t('logout')}
                        className='Btn-link flex-sm-align-center flex-sm-justify-center target'
                        title={t('logout')}
                        onClick={() => {
                          logoutUser()
                        }}
                        isEngagementEvent={false}
                        trackProps={{
                          clickType: 'button',
                          buttonName: 'log_out',
                          buttonText: t('logout'),
                          unitName: 'sidebar_menu',
                          unitLocation: 'modal',
                          componentId: navbar.sys.id,
                        }}
                      >
                        {user?.picture ? (
                          <img
                            alt=''
                            src={user?.picture}
                            className={`circle-sm ${styles.userImg}`}
                          />
                        ) : (
                          <SvgProfileFilled className='w-sm-4 m-sm-3' />
                        )}
                      </TrackClick>
                    ) : (
                      <TrackClick
                        as='button'
                        type='button'
                        aria-label={t('open-authentication')}
                        title={t('open-authentication')}
                        className='Btn-link flex-sm-align-center flex-sm-justify-center target'
                        data-testid='header-icon-open-authentication'
                        onClick={() => {
                          setIsOpened(false)
                          updateAccountModal?.(true)
                        }}
                        isEngagementEvent={false}
                        trackProps={{
                          clickType: 'button',
                          buttonName: 'login',
                          unitName: 'sidebar_menu',
                          unitLocation: 'modal',
                          componentId: navbar.sys.id,
                        }}
                      >
                        <SvgProfile className='w-sm-4 m-sm-3' />
                      </TrackClick>
                    )}

                    {/* Close panel button */}
                    <TrackClick
                      as='button'
                      type='button'
                      aria-label={t('close-menu')}
                      title={t('close-menu')}
                      className='Btn-link flex-sm-align-center flex-sm-justify-center target'
                      data-testid='header-icon-close-menu'
                      onClick={() => setIsOpened(false)}
                      isEngagementEvent
                      trackProps={{
                        eventType: 'dismiss',
                        unitName: 'sidebar_menu',
                        unitLocation: 'modal',
                        componentId: navbar.sys.id,
                      }}
                    >
                      <SvgClose className='w-sm-4 m-sm-3' />
                    </TrackClick>
                  </div>
                </div>

                {/* Search input */}
                {isSearchOpened && (
                  <form
                    className='mb-sm-5 p-sm-relative'
                    onSubmit={handleSearch}
                  >
                    <input
                      type='text'
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                      placeholder={t('search')}
                      className='form-control form-control--tall rounded-sm-5 pr-sm-6 pl-sm-4'
                      data-testid='header-input-search'
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <TrackClick
                      as='button'
                      title={t('search')}
                      aria-label={t('search')}
                      type='submit'
                      data-testid='header-submit-search'
                      className='Btn-link flex-sm-align-center flex-sm-justify-center target p-sm-absolute right-sm-2 top-sm-1'
                      isEngagementEvent
                      trackProps={{
                        eventType: 'search',
                        unitName: 'sidebar_menu',
                        unitLocation: 'modal',
                        submittedText: searchTerm,
                        componentId: navbar.sys.id,
                      }}
                    >
                      <SvgSearch className='w-sm-4' />
                    </TrackClick>
                  </form>
                )}

                <div>
                  {/* Collapsable links */}
                  <NavbarSidePanelLinkTree
                    items={
                      navbar?.itemsCollection?.items.filter((item) => !!item) ||
                      []
                    }
                    navbarId={navbar?.sys.id}
                  >
                    {/* Bottom links */}
                    <div className='mt-sm-4 mx-sm-1 mb-sm-1'>
                      <TrackClick
                        href='/donate'
                        locale='en'
                        className='Btn Btn--outline Btn--small no-underline'
                        isEngagementEvent={false}
                        trackProps={{
                          clickType: 'text',
                          isContent: true,
                          linkText: t('donate'),
                          linkedContentUrl: '/donate',
                          unitName: 'sidebar_menu',
                          unitLocation: 'modal',
                          componentId: navbar.sys.id,
                        }}
                      >
                        {t('donate')}
                      </TrackClick>
                    </div>
                  </NavbarSidePanelLinkTree>
                </div>
              </div>
            </FocusTrap>
          </>,
          document.body
        )}
    </>
  )
}

export default NavbarSidePanel
