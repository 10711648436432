/* eslint max-len: 0 */

import { ISvgProps } from './Svg.interface'

export default function SvgProfile({ className = '' }: ISvgProps) {
  return (
    <svg
      className={className}
      fill='currentColor'
      data-testid='svg-profile'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_13189_23681)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14 6C14 3.8 12.2 2 10 2C7.8 2 6 3.8 6 6C6 8.2 7.8 10 10 10C12.2 10 14 8.2 14 6ZM4 6C4 2.68 6.68 0 10 0C13.32 0 16 2.68 16 6C16 9.32 13.32 12 10 12C6.68 12 4 9.32 4 6ZM16 17.6C16 16.48 15.12 15.6 14 15.6H6C4.88 15.6 4 16.48 4 17.6V18H16V17.6ZM6 13.6H14C16.2 13.6 18 15.4 18 17.6V20H2V17.6C2 15.4 3.8 13.6 6 13.6Z'
        />
      </g>
      <defs>
        <clipPath id='clip0_13189_23681'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
