/* eslint max-len: 0 */

import { ISvgProps } from './Svg.interface'

export default function SvgCaretLeft({ className }: ISvgProps) {
  return (
    <svg
      className={className}
      data-testid='svg-caret-left'
      fill='currentColor'
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      focusable='false'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.492 7L34 10.5L18.971 25.475L34 40.499L30.492 44L15.465 29.027C13.515 27.088 13.511 23.926 15.456 21.979L30.492 7Z'
      />
    </svg>
  )
}
