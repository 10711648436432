import { ISvgProps } from './Svg.interface'

export default function SvgMenu({ className }: ISvgProps) {
  return (
    <svg
      className={className}
      fill='currentColor'
      data-testid='svg-menu'
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M0 7.5h50v5H0v-5zm0 15h50v5H0v-5zm50 15H0v5h50v-5z' />
    </svg>
  )
}
