/* eslint max-len: 0 */

import { ISvgProps } from './Svg.interface'

export default function SvgCaretRight({ className }: ISvgProps) {
  return (
    <svg
      className={className}
      data-testid='svg-caret-right'
      fill='currentColor'
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      focusable='false'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.508 43L16 39.5L31.029 24.525L16 9.501L19.508 6L34.535 20.973C36.485 22.912 36.489 26.074 34.544 28.021L19.508 43Z'
      />
    </svg>
  )
}
