'use client'

import {
  ComponentHelperExternalLinkFragment,
  ComponentHelperNavLinkFragment,
} from '@generated/schema'
import TrackClick from 'app/components/TrackClick/TrackClick.client'
import getContentUrl from 'app/utils/functions/getContentUrl'

interface INavLinkProps {
  item: ComponentHelperNavLinkFragment | ComponentHelperExternalLinkFragment
  navbarId: string
  index: number
  isFirstLayer?: boolean
}

export default function NavLink({
  item,
  navbarId,
  index,
  isFirstLayer,
}: INavLinkProps) {
  const url =
    item?.__typename === 'ComponentHelperExternalLink'
      ? item?.url
      : getContentUrl(item?.linkTo)

  const customStyle =
    item.__typename === 'ComponentHelperNavLink' && item.style === 'underline'
      ? 'text-underline'
      : 'no-underline'

  if (!url) return null

  return (
    <li
      className={
        isFirstLayer
          ? 'd-sm-flex flex-sm-align-center p-sm-relative mb-sm-4 pb-sm-4 border-sm-bottom border-static-grey3'
          : 'mb-sm-4 p-sm-relative'
      }
    >
      <TrackClick
        href={url}
        className={`${customStyle} ${
          isFirstLayer ? 'f-sm-5' : 'f-sm-4 text-foreground-midblueShade'
        } hover-underline text-regular stretched-link`}
        isEngagementEvent={false}
        trackProps={{
          clickType: 'text',
          isContent: true,
          linkText: item.label,
          linkedContentId: item.sys.id,
          linkedContentUrl: url,
          unitName: navbarId,
          unitLocation: 'header',
          positionInUnit: index + 1,
          componentId: navbarId,
        }}
      >
        {item.label}
      </TrackClick>
    </li>
  )
}
