/* eslint max-len: 0 */

import { ISvgProps } from './Svg.interface'

export default function SvgClose({ className }: ISvgProps) {
  return (
    <svg
      className={className}
      data-testid='svg-close'
      fill='currentColor'
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      focusable='false'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.0002 21.4644L44.4646 2L48.0001 5.53553L28.5358 24.9999L48.0005 44.4646L44.4649 48.0001L25.0002 28.5354L5.53553 48.0001L2 44.4646L21.4647 24.9999L2.00035 5.53553L5.53589 2L25.0002 21.4644Z'
      />
    </svg>
  )
}
