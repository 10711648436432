/* eslint max-len: 0 */

import { ISvgProps } from './Svg.interface'

export default function SvgSearch({ className }: ISvgProps) {
  return (
    <svg
      className={className}
      data-testid='svg-search'
      fill='currentColor'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.9412 7C11.9412 9.72894 9.72894 11.9412 7 11.9412C4.27106 11.9412 2.05882 9.72894 2.05882 7C2.05882 4.27106 4.27106 2.05882 7 2.05882C9.72894 2.05882 11.9412 4.27106 11.9412 7ZM14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM12.4 14L18.4 20L20 18.4L14 12.4L12.4 14Z'
      />
    </svg>
  )
}
